import dotenv from 'dotenv'
export const localStorageUserKey = "user";
dotenv.config()
export const version = "1.1.1"
export const baseUrl = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_SERVER_HOST}:${process.env.REACT_APP_SERVER_PORT}/`
// export const baseUrl = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_SERVER_HOST}/`
export const headers: Record<string, string> = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    Authorization: "",
};
